<template>
  <div class="fill-width">
    <v-expansion-panel
      class="school-filter__profile__expansion-panel"
    >
      <HeaderExpansionPanel
        :header-content="['actionIcon', 'svgIcon', 'title', 'appliedFilters']"
        :title="$t('map.filter.specialty')"
        :svg-icon="'specialty-icon.svg'"
        :svg-icon-size="'24'"
        :applied-filters="specialtyFilter"
      />
      <PanelTree
        :tree-object="specialtiesArray"
        :click-in-clean="clickInClean || selectedSpecialty.size === 0"
        :panel-content="['tree']"
        @setContentArray="setSpecialtyFilter"
      />
    </v-expansion-panel>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PanelTree from '@/components/molecules/expansion_panels/PanelTree.vue';
import HeaderExpansionPanel from '@/components/molecules/expansion_panels/HeaderExpansionPanel.vue';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'SpecialtyFilterSection',
  components: {
    HeaderExpansionPanel,
    PanelTree,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSpecialty: [],
    };
  },
  computed: {
    ...mapGetters({
      labelsByKey: 'explorer/labelsByKey',
      specialtyFilter: 'filters/specialtyFilter',
    }),
    specialties() {
      return this.labelsByKey(LABELS.SPECIALTIES);
    },
    specialtiesArray() {
      const specialtiesArray = this.specialties
        .reduce((acc, { area_name: areaName, subarea_name: subAreaName, ...rest }) => {
          if (!acc[areaName]) {
            acc[areaName] = [];
          }
          const isApplied = this.specialtyFilter?.has(rest.id) || false;
          acc[areaName].push({ subAreaName, isApplied, ...rest });
          return acc;
        }, {});

      return specialtiesArray;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setSpecialtyFilter([]);
        }
      },
    },
    specialtyFilter: {
      handler(newVal) {
        this.setSpecialtyFilter(newVal);
      },
    },
  },
  mounted() {
    this.setSpecialtyFilter(this.specialtyFilter);
  },
  methods: {
    setSpecialtyFilter(selectedContent) {
      this.$emit('setFilter', selectedContent);
      this.selectedSpecialty = selectedContent;
    },
  },
};
</script>
