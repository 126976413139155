<template>
  <div class="school-filter__content my-3">
    <MainHeader
      :small-title-text="'map.filter.distance'"
      :header-content="['smallTitle']"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <!-- Distance buttons -->
    <FlagButtons
      v-model="boundValues"
      :options="distanceArray"
    />
  </div>
</template>
<script>
import FlagButtons from '@/components/molecules/sections/FlagButtons.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';
import { mapGetters } from 'vuex';
import { DISTANCE_LABELS } from '@/constants/labels';
import { CAMPUS_PROPERTIES } from '@/constants/category';
import { buildCategoryObject } from '@/utils/categories/shared';

export default {
  name: 'DistanceFilterSection',
  components: {
    MainHeader,
    FlagButtons,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      boundValues: [],
    };
  },
  computed: {
    ...mapGetters({
      distanceFilter: 'filters/distanceFilter',
    }),
    distanceArray() {
      return Object.values(DISTANCE_LABELS).slice(0, 4).map((distanceLabel) => ({
        key: distanceLabel.id,
        ...buildCategoryObject(
          CAMPUS_PROPERTIES.DISTANCE,
          distanceLabel.TETHER_CATEGORY,
          { label: `< ${distanceLabel.UPPER_BOUND} km`, icon: distanceLabel.TRAVEL_METHOD },
        ),
      }));
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.boundValues = [];
        }
      },
    },
    distanceFilter: {
      handler(newVal) {
        this.boundValues = [...newVal];
      },
    },
    boundValues: {
      handler(newVal) {
        this.setDistanceFilter(newVal);
      },
    },
  },
  mounted() {
    this.boundValues = [...this.distanceFilter];
    this.setDistanceFilter(this.distanceFilter);
  },
  methods: {
    setDistanceFilter(selectedContent) {
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
