<template>
  <div class="fill-width">
    <v-expansion-panel
      class="school-filter__profile__expansion-panel"
      style="margin-top: 14px"
    >
      <HeaderExpansionPanel
        :header-content="['actionIcon', 'svgIcon', 'title', 'appliedFilters']"
        :title="$t('map.filter.infraestructure')"
        :svg-icon="'infrastructure-icon.svg'"
        :svg-icon-size="'24'"
        :applied-filters="infrastructureFilter"
      />
      <ContentExpansionPanel
        :button-array-content="infrastructureArray"
        :click-in-clean="clickInClean || selectedInfrastructure.size === 0"
        :panel-content="['buttonArrayInfo', 'buttonArrayText', 'buttonArraySvg']"
        :array-svg-icon-on="'plus-blue.svg'"
        :array-svg-icon-off="'close-white.svg'"
        @setContentArray="setInfrastructureFilter"
      />
    </v-expansion-panel>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ContentExpansionPanel from '@/components/molecules/expansion_panels/ContentExpansionPanel.vue';
import HeaderExpansionPanel from '@/components/molecules/expansion_panels/HeaderExpansionPanel.vue';

export default {
  name: 'InfrastructureFilterSection',
  components: {
    HeaderExpansionPanel,
    ContentExpansionPanel,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedInfrastructure: [],
    };
  },
  computed: {
    ...mapGetters({
      infrastructureLabels: 'options/infrastructureLabels',
      infrastructureFilter: 'filters/infrastructureFilter',
    }),
    infrastructureArray() {
      const infraestructureArray = this.infrastructureLabels.map(({
        infra_name: name,
        ...rest
      }) => ({
        name,
        ...rest,
      }));
      infraestructureArray.map((infrastructure) => {
        if (this.infrastructureFilter?.has(infrastructure.id)) {
          Object.assign(infrastructure, { isApplied: true });
        } else {
          Object.assign(infrastructure, { isApplied: false });
        }
        return infrastructure;
      });
      return infraestructureArray;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setInfrastructureFilter([]);
        }
      },
    },
    infrastructureFilter: {
      handler(newVal) {
        this.setInfrastructureFilter(newVal);
      },
    },
  },
  mounted() {
    this.setInfrastructureFilter(this.infrastructureFilter);
  },
  methods: {
    setInfrastructureFilter(selectedContent) {
      this.$emit('setFilter', selectedContent);
      this.selectedInfrastructure = selectedContent;
    },
  },
};
</script>
