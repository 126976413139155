<template>
  <v-snackbar
    :key="key"
    v-model="show"
    :color="color"
    :timeout="timeout"
    :top="mobile ? false : true"
    :bottom="mobile ? true : false"
  >
    <template #default>
      <div v-t="message" class="base-snackbar--text" />
    </template>
    <template #action="{ attrs }">
      <BaseButton outlined form v-bind="attrs" @click="show = false">
        {{ $t('snackbar.close') }}
      </BaseButton>
    </template>
  </v-snackbar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  SUCCESS, INFO, WARN, ERROR,
} from '@/store/utils.module';
import BaseButton from './BaseButton.vue';

export default {
  name: 'BaseSnackbar',
  components: {
    BaseButton,
  },
  data() {
    return {
      message: '',
      key: Date.now(),
    };
  },
  computed: {
    ...mapGetters({
      content: 'utils/content',
      timeout: 'utils/timeout',
      level: 'utils/level',
      storeShow: 'utils/show',
    }),
    color() {
      const { level } = this;
      if (level === SUCCESS) return 'success';
      if (level === INFO) return 'primary';
      if (level === WARN) return 'warning';
      if (level === ERROR) return 'red';
      return 'primary';
    },
    show: {
      get() {
        return this.storeShow;
      },
      set() {
        this.reset();
      },
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    content: {
      handler(newValue) {
        this.message = newValue;
        this.key = Date.now();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      reset: 'utils/resetSnackbar',
    }),
  },
};
</script>
