<template>
  <section
    class="school-filter school-filter--button-action-div"
  >
    <MainActionButtons
      v-if="!inFavorites"
      :main-action-button-content="['option']"
      :options-buttons-text="buttonsText[0]"
      option-legacy-color
      option-button-no-margin
      option-button-square
      class="mr-2"
      @setAction="$emit('cleanFilters')"
    />
    <MainActionButtons
      :main-action-button-content="['option']"
      :options-buttons-text="buttonsText[1]"
      option-legacy-color
      option-button-no-margin
      option-button-square
      active-option-button
      :option-button-loader="activeButtonLoader"
      class="ml-2"
      @setAction="$emit('applyFilter')"
    />
  </section>
</template>

<script>
import MainActionButtons from '@/components/molecules/sections/MainActionButtons.vue';

export default {
  name: 'FilterActionButtons',
  components: {
    MainActionButtons,
  },
  props: {
    // 2 text to buttons
    buttonsText: {
      type: Array,
      default() {
        return [];
      },
    },
    activeButtonLoader: {
      type: Boolean,
      default: false,
    },
    inFavorites: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
