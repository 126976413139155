<template>
  <div class="school-filter__content my-3">
    <MainHeader
      :small-title-text="'map.filter.admission_title'"
      :header-content="['smallTitle']"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <!-- Agreement buttons -->
    <ArrayButtonsContent
      :button-array-content="['arrayButtons', 'arrayButtonsText', 'arrayButtonsIcon']"
      :button-array="admissionArray"
      full-width
      :click-in-clean="clickInClean || !selectedAdmissions"
      centered-array-button
      text-width-auto
      text-left-safe-margin
      @setContentArray="setAdmissionFilter"
      @selectedContentSet="changeFaceIcon"
    />
  </div>
</template>
<script>
import ArrayButtonsContent from '@/components/molecules/sections/ArrayButtonsContent.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AdmissionFilterSection',
  components: {
    MainHeader,
    ArrayButtonsContent,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAdmissions: [],
    };
  },
  computed: {
    ...mapGetters({
      vacanciesFilter: 'filters/vacanciesFilter',
    }),
    admissionArray() {
      const currentYear = new Date().getFullYear();
      const nextThreeYears = [currentYear, currentYear + 1, currentYear + 2];
      return nextThreeYears.map((year) => ({
        id: year,
        name: `${this.$t('map.filter.vacancies_btn')} ${year}`,
        icon: this.vacanciesFilter?.has(year) ? 'white-face.svg' : 'face.svg',
        isApplied: this.vacanciesFilter?.has(year),
        iconSize: '25',
      }));
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setAdmissionFilter([]);
        }
      },
    },
    vacanciesFilter: {
      handler(newVal) {
        this.selectedAdmissions = newVal;
      },
    },
  },
  mounted() {
    this.selectedAdmissions = this.vacanciesFilter;
    this.$emit('setFilter', this.selectedAdmissions);
  },
  methods: {
    setAdmissionFilter(selectedContent) {
      this.selectedAdmissions = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
    changeFaceIcon(isContentSelected, id) {
      const index = this.admissionArray.findIndex((item) => item.id === id);
      if (index >= 0) {
        this.admissionArray[index].icon = isContentSelected ? 'white-face.svg' : 'face.svg';
      }
    },
  },
};
</script>
