var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-filter"},[_c('div',{staticClass:"map-filter__div"},[(_vm.locationName && _vm.locationName !== 'no-info' && !_vm.mobile)?_c('button',{staticClass:"map-filter__div__level",class:{
        'map-filter__div__level--selected': _vm.selectFilter === 0 || _vm.selectFilter === 1,
        'map-filter__div__level--tablet': _vm.tablet,
      },staticStyle:{"padding":"8px 20px 8px 12px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.showDialogs('location')}}},[_c('SvgIcon',{attrs:{"icon":_vm.getLocationIcon,"size":'24'}}),_c('div',[_vm._v(" "+_vm._s(!_vm.isGuestClickFirstTime.location && _vm.isGuest ? _vm.$t('desktop.header.location') : _vm.locationName)+" ")])],1):_vm._e(),(_vm.getLevelName && !_vm.mobile)?_c('button',{staticClass:"map-filter__div__level mr-1",class:{
        'map-filter__div__level--selected': _vm.selectFilter === 2,
        'map-filter__div__level--tablet': _vm.tablet,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.showDialogs('level')}}},[_vm._v(" "+_vm._s(_vm.getLevelName)+" ")]):_vm._e()]),_c('v-dialog',{attrs:{"fullscreen":""},on:{"click:outside":_vm.closeFilter},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}},[_c('SchoolsFilter',{on:{"ready":_vm.closeFilter}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }