<template>
  <div class="fill-width">
    <v-expansion-panel
      v-for="item in 1"
      :key="item.id"
      class="school-filter__profile__expansion-panel"
      style="margin-top: 14px"
    >
      <HeaderExpansionPanel
        :header-content="['actionIcon', 'svgIcon', 'title', 'appliedFilters']"
        :title="$t('map.filter.languages')"
        :svg-icon="'language-icon.svg'"
        :svg-icon-size="'24'"
        :applied-filters="languageFilter"
      />
      <ContentExpansionPanel
        :button-array-content="languageArray"
        :click-in-clean="clickInClean || selectedLanguages.size === 0"
        :panel-content="['buttonArrayInfo', 'buttonArrayText', 'buttonArraySvg']"
        :array-svg-icon-on="'plus-blue.svg'"
        :array-svg-icon-off="'close-white.svg'"
        @setContentArray="setLanguageFilters"
      />
    </v-expansion-panel>
  </div>
</template>
<script>
import ContentExpansionPanel from '@/components/molecules/expansion_panels/ContentExpansionPanel.vue';
import HeaderExpansionPanel from '@/components/molecules/expansion_panels/HeaderExpansionPanel.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LanguageFilterSection',
  components: {
    HeaderExpansionPanel,
    ContentExpansionPanel,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLanguages: [],
    };
  },
  computed: {
    ...mapGetters({
      languageLabels: 'options/languageLabels',
      languageFilter: 'filters/languageFilter',
    }),
    languageArray() {
      const languageArray = this.languageLabels.map(({
        language_name: name,
        ...rest
      }) => ({
        name,
        ...rest,
      }));
      languageArray.map((language) => {
        if (this.languageFilter?.has(language.id)) {
          Object.assign(language, { isApplied: true });
        } else {
          Object.assign(language, { isApplied: false });
        }
        return language;
      });
      return languageArray;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setLanguageFilters([]);
        }
      },
    },
    languageFilter: {
      handler(newVal) {
        this.setLanguageFilters(newVal);
      },
    },
  },
  mounted() {
    this.setLanguageFilters(this.languageFilter);
  },
  methods: {
    setLanguageFilters(selectedContent) {
      this.selectedLanguages = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
