<template>
  <div class="mobile-explorer-header">
    <div class="d-flex">
      <img
        class="mr-2"
        style="cursor: pointer"
        src="@/assets/icons/menu.svg"
        alt="menu"
        @click="$emit('toggleMenu')"
        @keydown.enter="$emit('toggleMenu')"
      />
    </div>
    <div
      class="d-flex align-center justify-center"
      :class="{ 'fill-width': showMobileSearchBar }"
    >
      <CampusSearchBar v-if="showMobileSearchBar" />
      <EditMainInfoBar v-if="!showMobileSearchBar" />
    </div>
    <SvgLogo
      v-if="!showMobileSearchBar"
      :class="!showCampusSearch ? 'pr-8' : ''"
      :logo="'inline-explorer-quality.svg'"
      :svg-width="'125'"
      no-margin
    />
    <v-icon
      v-if="mobile && showCampusSearch"
      medium
      color="#1E0C61"
      class="ml-2"
      @click="showMobileSearch"
    >
      mdi-magnify
    </v-icon>
  </div>
</template>
<script>
import EditMainInfoBar from '@/components/explorer/map/EditMainInfoBar.vue';
import CampusSearchBar from '@/components/explorer/map/CampusSearchBar.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';

export default {
  name: 'MobileExplorerHeader',
  components: {
    CampusSearchBar,
    EditMainInfoBar,
    SvgLogo,
  },
  props: {
    showCampusSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editingOrAddingStudent: false,
      showStudentRegisterModal: false,
      showMobileSearchBar: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    $route: {
      handler(to) {
        const editingOrAddingStudent = to.path === '/register_student';
        this.editingOrAddingStudent = editingOrAddingStudent;
      },
      immediate: true,
    },
    showStudentRegisterModal: {
      handler(newValue) {
        if (newValue === false) {
          this.setSteps({
            callback: () => this.reloadStudents({
              callback: () => this.resetNewStudentStore({}),
            }),
          });
        }
      },
    },
  },
  methods: {
    showMobileSearch() {
      if (this.showMobileSearchBar) {
        this.showMobileSearchBar = false;
        return this.showMobileSearchBar;
      }
      this.showMobileSearchBar = true;
      return this.showMobileSearchBar;
    },
  },
};
</script>
