var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"partners-svg-logo-container",style:({
    // FIXME: (selectedColor ? selectedColor : '#EBEBEB') Se hardcodeó porque
    // no están listos los diseños y con la Majo se decidió dejar el default gris
    'background-color': _vm.selectedPartner ? (_vm.selectedColor ? '#EBEBEB' : '#EBEBEB') : 'transparent',
    // FIXME: lo mismo para este: debiese ir 'border-color': selectedColor
    'border-color': '#DCDCDC',
  }),on:{"click":function($event){return _vm.logoClick()},"keydown":function($event){return _vm.logoClick()}}},[_c('img',{staticClass:"partners-svg-logo",attrs:{"src":_vm.logo,"width":_vm.svgWidth,"alt":_vm.alt}})])
}
var staticRenderFns = []

export { render, staticRenderFns }