<template>
  <v-icon :color="color">
    {{ name }}
  </v-icon>
</template>

<script>
export default {
  name: 'VueIcon',
  components: {},
  props: {
    color: {
      type: String,
      default: '#1A0C4C',
    },
    name: {
      type: String,
      default: '$expand',
    },
  },
};
</script>
