<template>
  <div
    class="school-filter__content my-3"
    style="margin-top: 0 !important;"
  >
    <MainHeader
      :small-title-text="'map.filter.payment'"
      :header-content="['smallTitle']"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <!-- Agreement buttons -->
    <FlagButtons
      v-model="boundValues"
      multi
      :options="paymentArray"
    />
  </div>
</template>
<script>
import FlagButtons from '@/components/molecules/sections/FlagButtons.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';
import { buildCategoryObject } from '@/utils/categories/shared';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { CAMPUS_PROPERTIES } from '@/constants/category';
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentFilterSection',
  components: {
    MainHeader,
    FlagButtons,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      boundValues: [],
    };
  },
  computed: {
    ...mapGetters({
      paymentFilter: 'filters/paymentFilter',
    }),
    paymentArray() {
      const propertyObjects = Object.entries(TENANT_CONFIGURATION.CATEGORIES.PAYMENT)
        .map(([id, category]) => ({ key: +id, ...buildCategoryObject(CAMPUS_PROPERTIES.PAYMENT, category) }));
      return propertyObjects;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.boundValues = [];
        }
      },
    },
    boundValues: {
      handler(newVal) {
        this.setPaymentFilter(newVal);
      },
    },
    paymentFilter: {
      handler(newVal) {
        this.boundValues = [...newVal];
      },
    },
  },
  mounted() {
    this.boundValues = [...this.paymentFilter];
  },
  methods: {
    setPaymentFilter(selectedContent) {
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
