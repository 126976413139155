<template>
  <div class="school-filter__content my-3">
    <MainHeader
      :small-title-text="'map.filter.dependency_title'"
      :header-content="['smallTitle']"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <!-- Dependency buttons -->
    <ArrayButtonsContent
      :button-array-content="['arrayButtons', 'arrayButtonsText']"
      :button-array="dependencyArray"
      :click-in-clean="clickInClean || selectedDependency.length === 0"
      :columns="2"
      text-no-margin
      @setContentArray="setDependencyFilters"
    />
  </div>
</template>
<script>
import ArrayButtonsContent from '@/components/molecules/sections/ArrayButtonsContent.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DependencyFilterSection',
  components: {
    MainHeader,
    ArrayButtonsContent,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDependency: [],
    };
  },
  computed: {
    ...mapGetters({
      dependencyLabels: 'options/sectorLabels',
      dependencyFilter: 'filters/dependencyFilter',
    }),
    dependencyArray() {
      const dependencyArray = this.dependencyLabels.map(({
        sector_name: name,
        ...rest
      }) => ({
        name,
        ...rest,
      }));
      dependencyArray.map((dependency) => {
        Object.assign(dependency, { isApplied: this.dependencyFilter?.has(dependency.id), icon: 'price-free.svg' });
        return dependency;
      });
      return dependencyArray;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setDependencyFilters([]);
        }
      },
    },
    dependencyFilter: {
      handler(newVal) {
        this.selectedDependency = [...newVal];
      },
    },
  },
  mounted() {
    this.setDependencyFilters(this.dependencyFilter);
  },
  methods: {
    setDependencyFilters(selectedContent) {
      this.selectedDependency = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
