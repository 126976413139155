<template>
  <section class="grid-number grid-cols-2">
    <section
      v-for="logo in logoArray"
      :key="logo.icon"
    >
      <PartnersLogo
        v-if="logo.icon"
        class="mr-5"
        :logo="logo.icon"
        :index="logo.index"
        :selected="logo.selected"
        :selected-color="logo.selectedColor"
        :click-in-clean="clickInClean"
        pointer
        @logoClick="setContentArray($event)"
      />
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import PartnersLogo from '@/components/atoms/logos/PartnersLogo.vue';

export default {
  name: 'LogoArrayContent',
  components: {
    PartnersLogo,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
    // id and name object in array
    logoArray: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selectedContent: [],
    };
  },
  computed: {
    ...mapGetters({
      partnershipFilter: 'filters/partnershipFilter',
    }),
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.$emit('selectedContentSet', false);
          this.selectedContent = [];
        }
      },
    },
  },
  methods: {
    setContentArray(labelId) {
      if (!this.selectedContent.includes(labelId)) {
        this.selectedContent.push(labelId);
      } else {
        let newList = [];
        newList = this.selectedContent.filter((content) => {
          if (content !== labelId) {
            return true;
          }
          return false;
        });
        this.selectedContent = newList;
      }
      return this.$emit('setContentArray', this.selectedContent);
    },
  },
};
</script>
