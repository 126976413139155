<template>
  <div
    class="school-filter__profile__panel"
  >
    <MainHeader
      :header-content="['primaryText', 'icon']"
      primary-text="map.filter.partnership_title"
      icon-name="partnership.svg"
      icon-size="24"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <ArrayLogoContent
      :logo-array="logoObject()"
      :click-in-clean="clickInClean || selectedPartnership.size === 0"
      @setContentArray="setPartnershipFilter"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ArrayLogoContent from '@/components/molecules/sections/ArrayLogoContent.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';

export default {
  name: 'PartnershipFilter',
  components: {
    MainHeader,
    ArrayLogoContent,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPartnership: new Set(),
    };
  },
  computed: {
    ...mapGetters({
      partnershipLabels: 'options/partnershipLabels',
      partnershipFilter: 'filters/partnershipFilter',
    }),
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setPartnershipFilter([]);
        }
      },
    },
    partnershipFilter: {
      handler(newVal) {
        this.setPartnershipFilter(newVal);
      },
    },
  },
  mounted() {
    this.setPartnershipFilter(this.partnershipFilter);
  },
  methods: {
    logoObject() {
      const logoObject = this.partnershipLabels.map((label) => {
        const logo = {
          icon: label.image,
          index: label.id,
          selected: this.partnershipFilter?.has(label.id),
          selectedColor: label.color ? `#${label.color}` : null,
        };
        return logo;
      });
      return logoObject;
    },
    setPartnershipFilter(selectedContent) {
      this.selectedPartnership = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
