<template>
  <div
    class="school-filter__content my-3"
    style="margin-top: 0 !important;"
  >
    <MainHeader
      :small-title-text="'map.filter.performance'"
      :header-content="['smallTitle']"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <!-- Agreement buttons -->
    <FlagButtons
      v-model="boundValues"
      :options="performanceArray"
      multi
      return-object
    />
  </div>
</template>
<script>
import FlagButtons from '@/components/molecules/sections/FlagButtons.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';
import { buildCategoryObject } from '@/utils/categories/shared';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { CAMPUS_PROPERTIES, TETHER_CATEGORY } from '@/constants/category';
import { mapGetters } from 'vuex';

export default {
  name: 'PerformanceFilterSection',
  components: {
    MainHeader,
    FlagButtons,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      boundValues: [],
    };
  },
  computed: {
    ...mapGetters({
      performanceFilter: 'filters/performanceFilter',
    }),
    performanceArray() {
      // FIXME: This is a workaround to make te performance filter work when a category has more than one ID
      // associated to it. This should be removed when the backend is able to handle this case.
      const groupedIds = Object.entries(TENANT_CONFIGURATION.CATEGORIES.PERFORMANCE)
        .reduce(
          (acc, [id, category]) => {
            if (category === TETHER_CATEGORY.NO_DATA) {
              return acc;
            }
            if (acc[category]) {
              acc[category].push(+id);
            } else {
              acc[category] = [+id];
            }
            return acc;
          },
          {},
        );
      const propertyObjects = Object.entries(groupedIds)
        .map(
          ([category, ids], index) => ({
            key: index,
            ids,
            ...buildCategoryObject(
              CAMPUS_PROPERTIES.PERFORMANCE,
              category,
            ),
          }),
        );
      return propertyObjects;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.boundValues = [];
        }
      },
    },
    boundValues: {
      handler(newVal) {
        this.setPerformanceFilter(newVal);
      },
    },
    performanceFilter: {
      handler(newVal) {
        this.boundValues = this.performanceArray.filter(
          (option) => option.ids.some((id) => newVal.has(+id)),
        );
      },
      immediate: true,
    },
  },
  methods: {
    setPerformanceFilter(selectedContent) {
      const ids = selectedContent.map((content) => content.ids).flat();
      this.$emit('setFilter', ids);
    },
  },
};
</script>
