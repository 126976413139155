<template>
  <section>
    <MainTitle
      v-if="headerContent.includes('mainTitle')"
      :text="mainTitleText"
      left-align
    />
    <SmallTitle
      v-if="headerContent.includes('smallTitle')"
      :text="smallTitleText"
      :center-left="leftSmallTitle"
      :primary="primaryColorSmallTitle"
      :no-margin="noMarginSmallTitle"
    />
    <div class="d-flex">
      <SvgIcon
        v-if="headerContent.includes('icon')"
        class="mr-5"
        style="width: 29px"
        :icon="iconName"
        :size="iconSize"
      />
      <PrimaryText
        v-if="headerContent.includes('primaryText')"
        :text="primaryText"
        tertiary-color
        bold
        align-left
      />
    </div>
  </section>
</template>

<script>
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';

export default {
  name: 'ContentExpansionPanel',
  components: {
    MainTitle,
    SmallTitle,
    PrimaryText,
    SvgIcon,
  },
  props: {
    headerContent: {
      type: Array,
      default() {
        return [];
      },
    },
    mainTitleText: {
      type: String,
      default: '',
    },
    smallTitleText: {
      type: String,
      default: '',
    },
    primaryText: {
      type: String,
      default: '',
    },
    leftSmallTitle: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '',
    },
    primaryColorSmallTitle: {
      type: Boolean,
      default: false,
    },
    noMarginSmallTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
