<template>
  <div
    class="partners-svg-logo-container"
    :style="{
      // FIXME: (selectedColor ? selectedColor : '#EBEBEB') Se hardcodeó porque
      // no están listos los diseños y con la Majo se decidió dejar el default gris
      'background-color': selectedPartner ? (selectedColor ? '#EBEBEB' : '#EBEBEB') : 'transparent',
      // FIXME: lo mismo para este: debiese ir 'border-color': selectedColor
      'border-color': '#DCDCDC',
    }"
    @click="logoClick()"
    @keydown="logoClick()"
  >
    <img
      class="partners-svg-logo"
      :src="logo"
      :width="svgWidth"
      :alt="alt"
    />
  </div>
</template>

<script>
export default {
  name: 'PartnersLogoRender',
  components: {},
  props: {
    logo: {
      type: String,
      default: 'face.svg',
    },
    index: {
      type: Number,
      default: null,
    },
    svgWidth: {
      type: String,
      default: 'auto',
    },
    alt: {
      type: String,
      default: 'SVG Logo',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectedColor: {
      type: String,
      default: '#EBEBEB',
    },
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPartner: this.selected,
    };
  },
  watch: {
    selected(newVal) {
      this.selectedPartner = newVal;
    },
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.selectedPartner = false;
        }
      },
    },
  },
  methods: {
    logoClick() {
      this.selectedPartner = !this.selectedPartner;
      this.$emit('logoClick', this.index);
    },
  },
};
</script>
<style scoped>
.partners-svg-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 16px 0 32px 0;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 2px;
  border: solid;
  border-width: 2px;
  border-radius: 20px;
  padding: 3px;
  cursor: pointer;
}
.partners-svg-logo {
  height: 30px;
}
</style>
