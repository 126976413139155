<template>
  <div class="school-filter__content my-3">
    <MainHeader
      small-title-text="map.filter.modality_title"
      :header-content="['smallTitle']"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <!-- Modality buttons -->
    <ArrayButtonsContent
      :button-array-content="['arrayButtons', 'arrayButtonsText']"
      :button-array="modalityArray"
      full-width
      :array-text-left-align="arrayButtonsLeftAlign"
      :click-in-clean="clickInClean || selectedModalities.length === 0"
      @setContentArray="setModalityFilter"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ArrayButtonsContent from '@/components/molecules/sections/ArrayButtonsContent.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';

export default {
  name: 'ModalityFilterSection',
  components: {
    MainHeader,
    ArrayButtonsContent,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
    arrayButtonsLeftAlign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedModalities: [],
    };
  },
  computed: {
    ...mapGetters({
      modalityFilter: 'filters/modalityFilter',
    }),
    modalityArray() {
      const modalityArray = [
        {
          name: 'Técnico Profesional',
          id: 'TP',
        },
        {
          name: 'Humanista Científico',
          id: 'HC',
        },
        {
          name: 'Artístico',
          id: 'AR',
        },
      ];
      modalityArray.map((modality) => {
        Object.assign(modality, { isApplied: this.modalityFilter?.has(modality.id) });
        return modality;
      });
      return modalityArray;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setModalityFilter([]);
        }
      },
    },
    modalityFilter: {
      handler(newVal) {
        this.selectedModalities = [...newVal];
      },
    },
  },
  mounted() {
    this.setModalityFilter(this.modalityFilter);
  },
  methods: {
    setModalityFilter(selectedContent) {
      this.selectedModalities = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
