<template>
  <v-expansion-panel-header
    class="school-filter__profile__expansion-panel__header"
    :color="color"
  >
    <template v-if="headerContent.includes('actionIcon')" #actions>
      <VueIcon />
    </template>
    <div class="fill-width d-flex">
      <SvgIcon v-if="headerContent.includes('svgIcon')" :icon="svgIcon" :size="svgIconSize" class="mr-5" />
      <PrimaryText
        v-if="headerContent.includes('title')"
        style="width: 70%"
        :text="title"
        tertiary-color
        bold
        align-left
      />
    </div>
    <PrimaryText
      v-if="headerContent.includes('appliedFilters') && appliedFilters?.size"
      :text="`(+${appliedFilters.size})`"
      tertiary-color
      bold
      style="width: 20%"
      align-left
    />
  </v-expansion-panel-header>
</template>

<script>
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import VueIcon from '@/components/atoms/icons/VueIcon.vue';
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';

export default {
  name: 'HeaderExpansionPanel',
  components: {
    PrimaryText,
    SvgIcon,
    VueIcon,

  },
  props: {
    color: {
      type: String,
      default: '',
    },
    headerContent: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: '',
    },
    svgIcon: {
      type: String,
      default: '',
    },
    svgIconSize: {
      type: String,
      default: '24',
    },
    appliedFilters: {
      type: Set,
      default() {
        return new Set();
      },
    },
  },
};
</script>
