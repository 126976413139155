<template>
  <div
    class="d-flex flex-row justify-between"
    :class="{ 'flex-wrap': columns }"
  >
    <Flag
      v-for="option in options"
      :key="option.key"
      :background="option.style.backgroundColor"
      :color="option.style.color"
      :label="option.label"
      :icon="option.icon"
      :styling="'icon-label'"
      :style="{
        width: `calc(96% / ${columns})`,
        marginTop: '0.5rem',
        height: '3.5rem',
      }"
      :outlined="!checkIsSelected(option)"
      @click.native="handleClick(option)"
    />
  </div>
</template>

<script>
import Flag from '@/components/molecules/flags/Flag.vue';

export default {
  name: 'FlagButtons',
  components: {
    Flag,
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    multi: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Number,
      default: 2,
    },
  },
  methods: {
    checkIsSelected(option) {
      const { key } = option;
      return this.returnObject
        ? this.value.some((stagedValue) => stagedValue.key === key)
        : this.value.includes(key);
    },
    handleClick(option) {
      const { key } = option;
      const optionValue = this.returnObject ? option : key;
      if (this.checkIsSelected(option)) {
        this.$emit(
          'input',
          this.value.filter((stagedValue) => (this.returnObject ? stagedValue.key : stagedValue) !== key),
        );
      } else if (this.multi) {
        this.$emit('input', [...this.value, optionValue]);
      } else {
        this.$emit('input', [optionValue]);
      }
    },
  },

};
</script>
