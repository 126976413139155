<template>
  <div class="fill-width">
    <v-expansion-panel
      v-for="(item, i) in 1"
      :key="i"
      class="school-filter__profile__expansion-panel"
      style="margin-top: 14px"
    >
      <HeaderExpansionPanel
        :header-content="['actionIcon', 'svgIcon', 'title', 'appliedFilters']"
        :title="$t('map.filter.sports')"
        :svg-icon="'sports-icon.svg'"
        :svg-icon-size="'24'"
        :applied-filters="sportFilter"
      />
      <ContentExpansionPanel
        :button-array-content="sportsArray"
        :click-in-clean="clickInClean || selectedSports.size === 0"
        :panel-content="['buttonArrayInfo', 'buttonArrayText', 'buttonArraySvg']"
        :array-svg-icon-on="'plus-blue.svg'"
        :array-svg-icon-off="'close-white.svg'"
        @setContentArray="setSportsFilters"
      />
    </v-expansion-panel>
  </div>
</template>
<script>
import ContentExpansionPanel from '@/components/molecules/expansion_panels/ContentExpansionPanel.vue';
import HeaderExpansionPanel from '@/components/molecules/expansion_panels/HeaderExpansionPanel.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SportsFilterSection',
  components: {
    HeaderExpansionPanel,
    ContentExpansionPanel,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSports: [],
    };
  },
  computed: {
    ...mapGetters({
      sportsLabels: 'options/sportsLabels',
      sportFilter: 'filters/sportFilter',
    }),
    sportsArray() {
      const sportsArray = this.sportsLabels.map(({
        sport_name: name,
        ...rest
      }) => ({
        name,
        ...rest,
      }));
      sportsArray.map((sport) => {
        Object.assign(sport, { isApplied: this.sportFilter?.has(sport.id) });
        return sport;
      });
      return sportsArray;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setSportsFilters([]);
        }
      },
    },
    sportFilter: {
      handler(newVal) {
        this.setSportsFilters(newVal);
      },
    },
  },
  mounted() {
    this.setSportsFilters(this.sportFilter);
  },
  methods: {
    setSportsFilters(selectedContent) {
      this.selectedSports = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
