<template>
  <div class="fill-width">
    <v-expansion-panel
      v-for="item in 1"
      :key="item"
      class="school-filter__profile__expansion-panel"
      style="margin-top: 14px"
    >
      <HeaderExpansionPanel
        :header-content="['actionIcon', 'svgIcon', 'title', 'appliedFilters']"
        :title="$t('map.filter.extracurricular')"
        :svg-icon="'extracurricular-icon.svg'"
        :svg-icon-size="'24'"
        :applied-filters="extracurricularFilter"
      />
      <ContentExpansionPanel
        :button-array-content="extracurricularArray"
        :click-in-clean="clickInClean || selectedExtracurriculars.size === 0"
        :panel-content="['buttonArrayInfo', 'buttonArrayText', 'buttonArraySvg']"
        :array-svg-icon-on="'plus-blue.svg'"
        :array-svg-icon-off="'close-white.svg'"
        @setContentArray="setExtracurricularFilters"
      />
    </v-expansion-panel>
  </div>
</template>
<script>
import ContentExpansionPanel from '@/components/molecules/expansion_panels/ContentExpansionPanel.vue';
import HeaderExpansionPanel from '@/components/molecules/expansion_panels/HeaderExpansionPanel.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ExtracurricularFilterSection',
  components: {
    HeaderExpansionPanel,
    ContentExpansionPanel,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedExtracurriculars: [],
    };
  },
  computed: {
    ...mapGetters({
      extracurricularLabels: 'options/extracurricularLabels',
      extracurricularFilter: 'filters/extracurricularFilter',
    }),
    extracurricularArray() {
      const extracurricularArray = this.extracurricularLabels.map(({
        extra_name: name,
        ...rest
      }) => ({
        name,
        ...rest,
      }));
      extracurricularArray.map((extracurricular) => {
        if (this.extracurricularFilter?.has(extracurricular.id)) {
          Object.assign(extracurricular, { isApplied: true });
        } else {
          Object.assign(extracurricular, { isApplied: false });
        }
        return extracurricular;
      });
      return extracurricularArray;
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setExtracurricularFilters([]);
        }
      },
    },
    extracurricularFilter: {
      handler(newVal) {
        this.setExtracurricularFilters(newVal);
      },
    },
  },
  mounted() {
    this.setExtracurricularFilters(this.extracurricularFilter);
  },
  methods: {
    setExtracurricularFilters(selectedContent) {
      this.selectedExtracurriculars = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
