<template>
  <v-expansion-panel-content>
    <div
      v-if="panelContent.includes('tree')"
      class="mt-3"
    >
      <v-container style="padding: 0px 0px">
        <div class="school-filter__chip">
          <div
            v-for="(value, key, index) in treeObject"
            :key="index"
            class="school-filter__chip__div"
          >
            <v-chip-group multiple>
              <v-chip
                :color="selectedAreas.includes(key) ? '#5627FF' : '#D9DBE9'"
                :text-color="selectedAreas.includes(key) ? 'white' : '#0C1461'"
                @click="selectArea(key, value)"
              >
                {{ key }}
              </v-chip>
            </v-chip-group>
            <div v-if="selectedAreas.includes(key) || isSubAreaSelected(value)">
              <v-chip-group multiple>
                <div class="school-filter__chip gap-1">
                  <button
                    v-for="(subArea, subIndex) in value"
                    :key="subIndex"
                    class="school-filter__button"
                    :value="subArea.id"
                    type="button"
                    :style="{ 'background-color': selectedContent.includes(subArea.id) ? '#B2C2FE' : '#EFF0F6' }"
                    @click="setContentArray(subArea.id)"
                  >
                    {{ subArea.subAreaName !== '' ? subArea.subAreaName : key }}
                  </button>
                </div>
              </v-chip-group>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </v-expansion-panel-content>
</template>

<script>

export default {
  name: 'PanelTree',
  props: {
    panelContent: {
      type: Array,
      default() {
        return [];
      },
    },
    // id and name object in array
    treeObject: {
      type: Object,
      default: () => ({}),
    },
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedContent: [],
      selectedAreas: [],
    };
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.selectedContent = [];
        }
      },
    },
  },
  mounted() {
    Object.keys(this.treeObject).forEach((key) => {
      this.treeObject[key].forEach((btn) => {
        if (btn.isApplied) {
          this.selectedContent.push(btn.id);
        }
      });
    });
  },
  methods: {
    selectArea(area, value) {
      if (this.selectedAreas.includes(area)) {
        this.selectedAreas = this.selectedAreas.filter((a) => a !== area);
        this.selectedContent = this.selectedContent
          .filter((content) => !value.map((subArea) => subArea.id).includes(content));
        this.$emit('setContentArray', this.selectedContent);
      } else {
        this.selectedAreas.push(area);
        this.selectedContent = [...this.selectedContent, ...value.map((subArea) => subArea.id)];
        this.$emit('setContentArray', this.selectedContent);
      }
    },
    setContentArray(labelId) {
      if (!this.selectedContent.includes(labelId)) {
        this.selectedContent.push(labelId);
      } else {
        this.selectedContent = this.selectedContent.filter((content) => content !== labelId);
      }
      this.$emit('setContentArray', this.selectedContent);
    },
    isSubAreaSelected(value) {
      return value.some((subArea) => this.selectedContent.includes(subArea.id));
    },
  },
};
</script>
