<template>
  <div class="school-filter__content my-3">
    <MainHeader
      small-title-text="map.filter.multimedia_title"
      :header-content="['smallTitle']"
      left-small-title
      primary-color-small-title
      no-margin-small-title
    />
    <!-- Agreement buttons -->
    <ArrayButtonsContent
      :button-array-content="['arrayButtons', 'arrayButtonsText', 'arrayButtonsIcon']"
      :button-array="audiovisualsArray"
      :click-in-clean="clickInClean || !selectedAudiovisuals"
      full-width
      array-text-left-align
      text-left-safe-margin
      @setContentArray="setMultimediaFilter"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ArrayButtonsContent from '@/components/molecules/sections/ArrayButtonsContent.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';

const AUDIOVISUALS_ICON_MAP = Object.freeze({
  1: 'drone',
  2: 'tour360',
  4: 'video',
});

export default {
  name: 'AudiovisualsFilterSection',
  components: {
    MainHeader,
    ArrayButtonsContent,
  },
  props: {
    clickInClean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAudiovisuals: [],
    };
  },
  computed: {
    ...mapGetters({
      audiovisualLabels: 'options/audiovisualLabel',
      multimediaFilter: 'filters/multimediaFilter',
    }),
    audiovisualsArray() {
      return this.audiovisualLabels.map(({
        id,
        audiovisual_name: name,
        ...rest
      }) => ({
        id,
        name,
        isApplied: this.multimediaFilter?.has(id),
        icon: `campus-properties/multimedia/${AUDIOVISUALS_ICON_MAP[id]}.svg`,
        iconSize: '30',
        ...rest,
      }));
    },
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.setMultimediaFilter([]);
        }
      },
    },
    multimediaFilter: {
      handler(newVal) {
        this.selectedAudiovisuals = newVal;
      },
    },
  },
  mounted() {
    this.selectedAudiovisuals = this.multimediaFilter;
    this.$emit('setFilter', this.selectedAudiovisuals);
  },
  methods: {
    setMultimediaFilter(selectedContent) {
      this.selectedAudiovisuals = selectedContent;
      this.$emit('setFilter', selectedContent);
    },
  },
};
</script>
