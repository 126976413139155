<template>
  <div class="map-filter">
    <div class="map-filter__div">
      <button
        v-if="locationName && locationName !== 'no-info' && !mobile"
        type="button"
        class="map-filter__div__level"
        :class="{
          'map-filter__div__level--selected': selectFilter === 0 || selectFilter === 1,
          'map-filter__div__level--tablet': tablet,
        }"
        style="padding: 8px 20px 8px 12px;"
        @click="showDialogs('location')"
      >
        <SvgIcon :icon="getLocationIcon" :size="'24'" />
        <div>
          {{
            !isGuestClickFirstTime.location && isGuest ? $t('desktop.header.location') : locationName
          }}
        </div>
      </button>
      <button
        v-if="getLevelName && !mobile"
        type="button"
        class="map-filter__div__level mr-1"
        :class="{
          'map-filter__div__level--selected': selectFilter === 2,
          'map-filter__div__level--tablet': tablet,
        }"
        @click="showDialogs('level')"
      >
        {{ getLevelName }}
      </button>
    </div>
    <v-dialog v-model="showFilter" fullscreen @click:outside="closeFilter">
      <SchoolsFilter @ready="closeFilter" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import schoolsFilter from '@/components/explorer/map/SchoolsFilter.vue';
import trackMixPanel from '@/utils/mixpanel';
import { getLocaleProperty } from '@/utils/locale';

export default {
  name: 'EditMainInfoBar',
  components: {
    schoolsFilter,
    SvgIcon,
  },
  data() {
    return {
      selectFilter: null,
      showFilter: false,
    };
  },
  computed: {
    ...mapGetters({
      isGuestClickFirstTime: 'authentication/isGuestClickFirstTime',
      currentStudent: 'authentication/currentStudent',
      isGuest: 'authentication/isGuest',
      guestLocation: 'authentication/guestLocation',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      activeGrades: 'authentication/activeGrades',
      gradeOptions: 'options/grades',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tablet() {
      return this.$vuetify.breakpoint.md;
    },
    getLevelName() {
      const { grades, source } = this.activeGrades;
      if (source === 'default' || grades.length === 0) {
        return this.$t('desktop.header.grade');
      }
      const gradeLabel = this.gradeOptions.find((grade) => grade.id === grades[0]);
      const gradeName = getLocaleProperty(gradeLabel, 'grade_name');
      return grades.length > 1
        ? `${gradeName} (+${grades.length - 1})`
        : gradeName;
      // If there is no grade to display, we return the default label
    },
    getLocationIcon() {
      if (this.isGuest) {
        return this.selectFilter === 0 ? 'home-white.svg' : 'home-primary.svg';
      }
      if (
        this.currentStudent
        && Object.keys(this.currentStudent).length > 0
        && this.currentStudent.address
        && this.currentStudent.address[0].address_name
      ) {
        if (this.currentStudent.address[0].address_name.id === 1) {
          return this.selectFilter === 0 ? 'home-white.svg' : 'home-primary.svg';
        }
        if (this.currentStudent.address[0].address_name.id === 2) {
          return this.selectFilter === 0 ? 'job-white.svg' : 'job-primary.svg';
        }
        if (
          this.currentStudent.address[0].address_name.id !== 2
          && this.currentStudent.address[0].address_name.id !== 1
        ) {
          return this.selectFilter === 0 ? 'pin-white.svg' : 'pin-primary.svg';
        }
      }
      if (
        this.legalGuardianAddress && this.legalGuardianAddress.length > 0
        && this.legalGuardianAddress[0].address_name
      ) {
        if (this.legalGuardianAddress[0].address_name.id === 1) {
          return this.selectFilter === 0 ? 'home-white.svg' : 'home-primary.svg';
        }
        if (this.legalGuardianAddress[0].address_name.id === 2) {
          return this.selectFilter === 0 ? 'job-white.svg' : 'job-primary.svg';
        }
        if (
          this.legalGuardianAddress[0].address_name.id !== 2
          && this.legalGuardianAddress[0].address_name.id !== 1
        ) {
          return this.selectFilter === 0 ? 'pin-white.svg' : 'pin-primary.svg';
        }
      }
      return this.selectFilter === 0 ? 'pin-white.svg' : 'pin-primary.svg';
    },
    locationName() {
      if (this.isGuest) {
        if (this.guestLocation && Object.keys(this.guestLocation).length > 0) {
          return this.guestLocation.municipalityName;
        }
        return this.$t('desktop.header.location');
      }
      if (
        Object.keys(this.currentStudent).length > 0
        && this.currentStudent.address
        && this.currentStudent.address.length > 0
      ) {
        if (Object.keys(this.currentStudent.address[0].address_name || {}).length > 0) {
          return this.getLocationName(this.currentStudent.address[0]?.address_name?.id);
        }
        return this.$t('desktop.header.location_type.my_location');
      }
      if (
        this.legalGuardianAddress
        && this.legalGuardianAddress.length > 0
         && Object.keys(this.currentStudent).length === 0
      ) {
        if (this.legalGuardianAddress[0]?.address_name) {
          return this.getLocationName(this.legalGuardianAddress[0].address_name.id);
        }
        if (this.legalGuardianAddress[0].address_details === ' ' && !this.legalGuardianAddress[0].location) {
          return this.$t('desktop.header.location');
        }
        return this.$t('desktop.header.location_type.my_location');
      }
      return this.$t('desktop.header.location');
    },
  },
  methods: {
    getLocationName(locationTypeId) {
      if (locationTypeId === 1) {
        return this.$t('desktop.header.location_type.home');
      }
      if (locationTypeId === 2) {
        return this.$t('desktop.header.location_type.work');
      }
      if (locationTypeId !== 2 && locationTypeId !== 1
      ) {
        return this.$t('desktop.header.location_type.my_location');
      }
      return this.$t('desktop.header.location_type.my_location');
    },
    showDialogs(option) {
      if (option === 'location') {
        this.$emit('showEditLocation');
        trackMixPanel('map_location_click');
      }
      if (option === 'level') {
        trackMixPanel('map_grade_click');
        this.$emit('showEditGrade');
      }
    },
    closeFilter() {
      this.selectFilter = -1;
      this.showFilter = false;
    },
  },
};
</script>
