<template>
  <v-expansion-panel-content>
    <div
      v-if="panelContent.includes('buttonArrayInfo')"
      class="school-filter__profile__div school-filter__profile__div__flex mt-3"
    >
      <div
        v-for="label in buttonArrayContent"
        :key="label.id"
        class="school-filter__profile__div__box mr-1 mt-1"
        :class="{
          'school-filter__profile__div__box--selected': selectedContent.includes(label.id),
        }"
        @click="setContentArray(label.id)"
        @keydown="/*TODO Accessability*/"
      >
        <PrimaryText
          v-if="panelContent.includes('buttonArrayText')"
          :text="label.name"
          tertiary-color
          small
          :font-family="'roboto'"
          :invert-tertiary-color="selectedContent.includes(label.id)"
          align-left
        />
        <div v-if="panelContent.includes('buttonArraySvg')" style="width: 9px; margin-left: 5px">
          <SvgIcon v-if="!selectedContent.includes(label.id)" :icon="arraySvgIconOn" :size="'9px'" />
          <SvgIcon v-else :icon="arraySvgIconOff" :size="'9px'" />
        </div>
      </div>
    </div>
  </v-expansion-panel-content>
</template>

<script>
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';

export default {
  name: 'ContentExpansionPanel',
  components: {
    SvgIcon,
    PrimaryText,
  },
  props: {
    panelContent: {
      type: Array,
      default() {
        return [];
      },
    },
    // id and name object in array
    buttonArrayContent: {
      type: Array,
      default: null,
    },
    clickInClean: {
      type: Boolean,
      default: false,
    },
    arraySvgIconOn: {
      type: String,
      default: '',
    },
    arraySvgIconOff: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedContent: [],
    };
  },
  watch: {
    clickInClean: {
      handler(newVal) {
        if (newVal) {
          this.selectedContent = [];
        }
      },
    },
  },
  mounted() {
    this.buttonArrayContent.forEach((btn) => {
      if (btn.isApplied) {
        this.selectedContent.push(btn.id);
      }
    });
  },
  methods: {
    setContentArray(labelId) {
      if (!this.selectedContent.includes(labelId)) {
        this.selectedContent.push(labelId);
      } else {
        let newList = [];
        newList = this.selectedContent.filter((content) => {
          if (content !== labelId) {
            return true;
          }
          return false;
        });
        this.selectedContent = newList;
      }
      this.$emit('setContentArray', this.selectedContent);
    },
  },
};
</script>
